import React from 'react';
import { AiFillHome, AiOutlineFileSearch } from 'react-icons/ai';
import { MdAttachEmail, MdSecurity, MdWifiCalling3 } from 'react-icons/md';
import { FaClipboardList, FaMoneyCheckAlt } from 'react-icons/fa';
import { GoFileMedia } from 'react-icons/go';
import groups from './userGroups';
import { GiEgyptianBird, GiPineTree } from 'react-icons/gi';
import { VscNotebookTemplate } from 'react-icons/vsc';

const navData = [
  {
    title: 'Home',
    path: '/',
    componentName: 'Home',
    icon: <AiFillHome />,
    accessGroup: groups.DEFAULT,
  },
  {
    title: 'Segment Creator',
    path: '/segment',
    componentName: 'SegmentCreator',
    icon: <VscNotebookTemplate />,
    accessGroup: groups.BACK_OFFICE,
  },
  {
    title: 'Model Controls',
    path: '/model-controls',
    componentName: 'ModelControls',
    icon: <MdWifiCalling3 />,
    accessGroup: groups.BACK_OFFICE,
  },
  {
    title: 'Media',
    path: '/media',
    componentName: 'Media',
    icon: <GoFileMedia />,
    accessGroup: groups.DEFAULT,
  },
  {
    title: 'Woodpecker',
    path: '/woodpecker',
    componentName: 'Woodpecker',
    icon: <GiEgyptianBird />,
    accessGroup: groups.DEFAULT,
  },
  {
    title: 'Juniper',
    path: '/juniper/campaigns',
    componentName: 'Juniper',
    icon: <GiPineTree />,
    accessGroup: groups.ADMIN,
  },
  {
    title: 'Access Control',
    path: '/access-control',
    componentName: 'AccessControl',
    icon: <MdSecurity />,
    accessGroup: groups.MANAGER,
  },
  {
    title: 'Internal Collections Manager',
    path: '/meta',
    componentName: 'Meta',
    icon: <FaMoneyCheckAlt />,
    accessGroup: groups.ADMIN || groups.MANAGER,
  },
  {
    title: 'Login',
    path: '/login',
    componentName: 'Login',
    hiddenFromNav: true,
    public: true,
  },
];

const juniperData = [
  {
    title: 'Campaigns', 
    path: '/juniper/campaigns',
    componentName: 'Campaigns',
    hiddenFromNav: true,
  },
  {
    title: 'Subjects', 
    path: '/juniper/subjects',
    componentName: 'Subjects',
    hiddenFromNav: true,
  },
  {
    title: 'Images', 
    path: '/juniper/images',
    componentName: 'Images',
    hiddenFromNav: true,
  },{
    title: 'Templates', 
    path: '/juniper/templates',
    componentName: 'Templates',
    hiddenFromNav: true,
  },
  {
    title: 'Audit', 
    path: '/juniper/audit',
    componentName: 'Audit',
    hiddenFromNav: true,
  }
]

const woodpeckerData = [
  {
    title: 'Home', 
    path: '/woodpecker',
    componentName: 'Home',
    hiddenFromNav: true,
  },
  {
    title: 'Quiq', 
    path: '/woodpecker/quiq',
    componentName: 'Quiq',
    hiddenFromNav: true,
  },
  {
    title: 'Manager', 
    path: '/woodpecker/manager',
    componentName: 'Manager',
    hiddenFromNav: true,
  },
  {
    title: 'Manual Outreach', 
    path: '/woodpecker/manout',
    componentName: 'ManualOut',
    hiddenFromNav: true,
  }
]


export {navData, juniperData, woodpeckerData};

export function userCanAccessPage(page, userGroups) {
  return page.public || userGroups.includes(groups.ADMIN) || userGroups.includes(page.accessGroup);
}

export function userIsManager(userGroups) {
  return userGroups.includes(groups.MANAGER) || userGroups.includes(groups.ADMIN);
}